import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { PortfolioData } from "../Data";

export default function Portfolio() {
  const particle = React.useRef(null);

  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  const categories = [
    {
      category_id: "app_design",
      title: "App Design",
    },
    {
      category_id: "app_development",
      title: "App Development",
    },
    {
      category_id: "branding",
      title: "Branding",
    },
    {
      category_id: "it_solutions",
      title: "It Solutions",
    },
  ];
  const [portSearch, setPortSearch] = useState(PortfolioData);
  // console.log(portSearch);

  const filterPortfolio = (category_id) => {
    if (category_id != "") {
      const updatePortfolios = PortfolioData.filter((curPortfolio) => {
        return curPortfolio.category_id === category_id;
      });
      setPortSearch(updatePortfolios);
    } else {
      setPortSearch(PortfolioData);
    }
  };
  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2 data-aos="fade-up">Portfolio</h2>
                <p data-aos="fade-up" data-aos-delay="400">
                Discover our diverse IT portfolio, showcasing innovative solutions, cutting-edge technology, and client success stories. Empowering businesses through digital transformation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="portfolio" className="portfolio section-grey">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Our Portfolio</h2>
            <p>Discover Our Excellence in Design, Development, and IT Solutions</p>
          </div>
          <div
            className="portfolio-isotope"
            data-portfolio-filter="*"
            data-portfolio-layout="masonry"
            data-portfolio-sort="original-order"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div>
              <ul className="portfolio-flters">
                <li className="filter-active">
                  <NavLink onClick={() => filterPortfolio("")} to="">
                    All
                  </NavLink>
                </li>
                <li className="filter-active">
                  <NavLink onClick={() => filterPortfolio("app_design")} to="">
                    App Design
                  </NavLink>
                </li>
                <li className="filter-active">
                  <NavLink
                    onClick={() => filterPortfolio("app_development")}
                    to=""
                  >
                    App Development
                  </NavLink>
                </li>
                <li className="filter-active">
                  <NavLink onClick={() => filterPortfolio("branding")} to="">
                    Branding
                  </NavLink>
                </li>
                <li className="filter-active">
                  <NavLink onClick={() => filterPortfolio("itsolution")} to="">
                    It Solutions
                  </NavLink>
                </li>
              </ul>
              {/* <!-- End Portfolio Filters --> */}
            </div>
            <div className="row gy-4 portfolio-container">
              {portSearch?.map((item) => (
                <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                  <div className="portfolio-wrap">
                    <a
                      target="blank"
                      href="/assets/images/portfolio/large.jpg"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox"
                    >
                      <img
                        src={item.image}
                        // src="/assets/images/portfolio/app-small-1.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                    <div className="portfolio-info">
                      <h4>
                        <a href="#" title="More Details">
                          {item.title}
                        </a>
                      </h4>
                      <div className="project-deatils-aside">
                        <div className="project-btn">
                          <a href="#" className="fill-btn">
                            View Project
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* <!-- End Portfolio Item --> */}

              {/* <div className="col-xl-4 col-md-6 portfolio-item filter-product">
                  <div className="portfolio-wrap">
                    <a
                      href="/assets/images/portfolio/large.jpg"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox"
                    >
                      <img
                        src="/assets/images/portfolio/product-small-1.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                    <div className="portfolio-info">
                      <h4>
                        <a href="#" title="More Details">
                          Product 1
                        </a>
                      </h4>
                      <div className="project-deatils-aside">
                        <div className="project-btn">
                          <a href="#" className="fill-btn">
                            View Project
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              {/* <!-- End Portfolio Item --> */}

              {/* <div className="col-xl-4 col-md-6 portfolio-item filter-branding">
                  <div className="portfolio-wrap">
                    <a
                      href="/assets/images/portfolio/large.jpg"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox"
                    >
                      <img
                        src="/assets/images/portfolio/branding-small-1.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                    <div className="portfolio-info">
                      <h4>
                        <a href="#" title="More Details">
                          Branding 1
                        </a>
                      </h4>
                      <div className="project-btn">
                        <a href="#" className="fill-btn">
                          View Project
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
              {/* <!-- End Portfolio Item --> */}

              {/* <div className="col-xl-4 col-md-6 portfolio-item filter-books">
                  <div className="portfolio-wrap">
                    <a
                      href="/assets/images/portfolio/large.jpg"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox"
                    >
                      <img
                        src="/assets/images/portfolio/books-small-1.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                    <div className="portfolio-info">
                      <h4>
                        <a href="#" title="More Details">
                          Books 1
                        </a>
                      </h4>
                      <div className="project-btn">
                        <a href="#" className="fill-btn">
                          View Project
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
              {/* <!-- End Portfolio Item --> */}

              {/* <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                  <div className="portfolio-wrap">
                    <a
                      href="/assets/images/portfolio/large.jpg"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox"
                    >
                      <img
                        src="/assets/images/portfolio/app-small-2.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                    <div className="portfolio-info">
                      <h4>
                        <a href="#" title="More Details">
                          App 2
                        </a>
                      </h4>
                      <div className="project-btn">
                        <a href="#" className="fill-btn">
                          View Project
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
              {/* <!-- End Portfolio Item --> */}

              {/* <div className="col-xl-4 col-md-6 portfolio-item filter-branding">
                  <div className="portfolio-wrap">
                    <a
                      href="/assets/images/portfolio/large.jpg"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox"
                    >
                      <img
                        src="/assets/images/portfolio/branding-small-2.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                    <div className="portfolio-info">
                      <h4>
                        <a href="#" title="More Details">
                          Branding 2
                        </a>
                      </h4>
                      <div className="project-deatils-aside">
                        <div className="project-btn">
                          <a href="#" className="fill-btn">
                            View Project
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              {/* <!-- End Portfolio Item --> */}
            </div>
            {/* <!-- End Portfolio Container --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Portfolio Section --> */}

      {/* <div className="pricing section">
        <div className="container-fluid">
          <div className="container">
            <div className="section-header">
              <h2>Pricing Plans</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card text-center">
                  <div className="title">
                    <img
                      src="/assets/images/icons/pricing-basic.png"
                      alt="icon"
                    />
                    <h2>Basic</h2>
                  </div>
                  <div className="price">
                    <h4>
                      <sup>$</sup>25
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited GB Space
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        30 Domain Names
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free SSL
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Daily Backup
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Templates
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Email
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        10 Databases
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited Email Address
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Live Support
                      </li>
                    </ul>
                  </div>
                  <a href="#">Order Now</a>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card text-center">
                  <div className="title">
                    <img
                      src="/assets/images/icons/pricing-standard.png"
                      alt="icon"
                    />
                    <h2>Standard</h2>
                  </div>
                  <div className="price">
                    <h4>
                      <sup>$</sup>50
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited GB Space
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        30 Domain Names
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free SSL
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Daily Backup
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Templates
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Email
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        10 Databases
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited Email Address
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Live Support
                      </li>
                    </ul>
                  </div>
                  <a href="#">Order Now</a>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card text-center">
                  <div className="title">
                    <img
                      src="/assets/images/icons/pricing-premium.png"
                      alt="icon"
                    />
                    <h2>Premium</h2>
                  </div>
                  <div className="price">
                    <h4>
                      <sup>$</sup>100
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited GB Space
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        30 Domain Names
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free SSL
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Daily Backup
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Templates
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Email
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        10 Databases
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited Email Address
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Live Support
                      </li>
                    </ul>
                  </div>
                  <a href="#">Order Now</a>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card text-center">
                  <div className="title">
                    <img
                      src="/assets/images/icons/pricing-custom.png"
                      alt="icon"
                    />
                    <h2>Custom</h2>
                  </div>
                  <div className="price">
                    <h4>
                      <sup>$</sup>500
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited GB Space
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        30 Domain Names
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free SSL
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Daily Backup
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Templates
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Email
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        10 Databases
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited Email Address
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Live Support
                      </li>
                    </ul>
                  </div>
                  <a href="#">Order Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
