export const PortfolioData = [
    {
        'id':1,
        'category_id': "app_design",
        'category': "App Design",
        'title':"App 1",
        'image':"/assets/images/portfolio/app-small-1.jpg",
        'lg_image':"/assets/images/portfolio/large.jpg",
    },
    {
        'id':2,
        'category_id': "app_development",
        'category': "App Development",
        'title':"Product 1",
        'image':"/assets/images/portfolio/product-small-1.jpg",
        'lg_image':"/assets/images/portfolio/large.jpg",
    },
    {
        'id':3,
        'category_id': "branding",
        'category': "Branding",
        'title':"Branding 1",
        'image':"/assets/images/portfolio/branding-small-1.jpg",
        'lg_image':"/assets/images/portfolio/large.jpg",
    },
    {
        'id':4,
        'category_id': "itsolution",
        'category': "Itsolution",
        'title':"Books 1",
        'image':"/assets/images/portfolio/books-small-1.jpg",
        'lg_image':"/assets/images/portfolio/large.jpg",
    },
    {
        'id':5,
        'category_id': "app_design",
        'category': "App Design",
        'title':"App 2",
        'image':"/assets/images/portfolio/app-small-2.jpg",
        'lg_image':"/assets/images/portfolio/large.jpg",
    },
    {
        'id':6,
        'category_id': "branding",
        'category': "Branding",
        'title':"Branding 2",
        'image':"/assets/images/portfolio/branding-small-2.jpg",
        'lg_image':"/assets/images/portfolio/large.jpg",
    },
]